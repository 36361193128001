<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" title_name="在线登记详情"></nav-bar-component>
        <div>
            <van-cell-group inset >
                <van-cell title="状态">
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="details.state===0">待分配</van-tag>
                        <van-tag plain type="primary" v-else-if="details.state===1">已分配</van-tag>
                        <van-tag plain type="warning" v-else-if="details.state===2">装卸中</van-tag>
                        <van-tag plain type="success" v-else-if="details.state===3">已完成</van-tag>
                        <van-tag plain type="danger" v-else-if="details.state===4">已取消</van-tag>
                        <van-tag plain type="danger" v-else-if="details.state===5">已过期</van-tag>
                    </template>
                </van-cell>
                <van-cell title="业务类型" :value="details.car_business_type" />
                <van-cell v-if="details.box_num" title="集装箱号" :value="details.box_num" />
                <van-cell title="车辆类型" :value="details.car_size_type" />
                <van-cell title="车牌号" :value="details.car_number" />
                <van-cell title="集装箱号" :value="details.box_num" />
                <van-cell title="司机姓名" :value="details.car_driver_name" />
                <van-cell title="联系电话" :value="details.car_driver_phone" />
                <van-cell title="身份证号" :value="details.car_driver_icard" />
                <van-cell title="装卸平台" :value="details.with_terrace_name" />
                <van-cell title="排队号" :value="details.list_num" />
                <van-cell title="装卸开始时间" :value="details.start_time" />
                <van-cell title="装卸结束时间" :value="details.end_time" />
                <van-cell title="身份证号" :value="details.car_driver_icard" />
            </van-cell-group>
            <div class="sub_button">
                <van-button round block type="warning" @click="send_phone" v-if="(details.notice === 0) && (details.state === 1) && (this.$_has('短信通知车辆入场'))">通知入场</van-button>
                <van-button round block type="default" v-else-if="(details.notice === 1) && (details.state === 1) && (this.$_has('短信通知车辆入场'))">已通知</van-button>
            </div>
            <div class="sub_button">
                <van-button round block type="info" @click="show = true" v-if="(details.state === 0) && (this.$_has('在线登记分配'))">分配平台</van-button>
            </div>
            <div class="sub_button">
                <van-button round block type="info" @click="show = true" v-if="(details.state === 1) && (this.$_has('在线登记分配'))">更换平台</van-button>
            </div>
            <div class="sub_button">
                <van-button round block type="primary" @click="load_start" v-if="(details.state === 1) && (this.$_has('车辆开始装卸'))">开始装卸</van-button>
            </div>
            <div class="sub_button">
                <van-button round block type="danger" @click="load_end" v-if="(details.state === 2) && (this.$_has('车辆结束装卸'))">装卸完成</van-button>
            </div>
            <div class="sub_button">
                <van-button round block type="default" @click="go_back">返 回</van-button>
            </div>
        </div>
        <van-dialog v-model:show="show" title="更换平台" show-cancel-button @close="close_distribute" @confirm="submit_distribute">
            <van-radio-group v-model="terrace_id">
                <van-cell-group>
                    <van-cell clickable @click="radio = item.id" v-for="item in AllTerrace" :key="item.id">
                        <template #title>
                            <span>{{item.name}}</span>
                        </template>
                        <template #right-icon>
                            <van-radio :name="item.id" />
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-radio-group>
        </van-dialog>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'

import { terrace_list_request } from "@/network/list.js"
import {
    car_driver_appoint_staff_details_request,
    car_driver_appoint_staff_load_start_request,
    car_driver_appoint_staff_load_end_request,
    car_driver_appoint_staff_send_phone_request,
    car_driver_appoint_staff_distribute_request
} from '@/network/WareHouse/CarDriverAppointStaff'

export default {
    name:'CarDriverCarDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            id: 0,
            details: {
                state: '',
                car_business_type: '',
                box_num: '',
                car_size_type: '',
                car_number:'',
                car_driver_name: '',
                car_driver_phone: '',
                car_driver_icard: '',
                with_terrace_name: '',
                list_num: '',
                start_time: '',
                end_time: '',
                notice: ''
            },
            edit: false,
            show: false,
            terrace_id: '',
            AllTerrace: []
        }
    },
    computed:{},
    methods:{
        get_car_driver_appoint_details() {
            this.$store.commit('true_loading')
            car_driver_appoint_staff_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        go_back() {
            this.$router.go(-1);//返回上一层
        },
        load_start() {
            this.$store.commit('true_loading')
            car_driver_appoint_staff_load_start_request({ id: this.id })
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('已开始装卸')
                        this.get_car_driver_appoint_details()
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        load_end() {
            this.$store.commit('true_loading')
            car_driver_appoint_staff_load_end_request({ id: this.id })
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('已结束装卸')
                        this.get_car_driver_appoint_details()
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        send_phone() {
            this.$store.commit('true_loading')
            car_driver_appoint_staff_send_phone_request({ id: this.id })
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('已发送短信通知')
                        this.details.notice = 1
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        close_distribute() {
            this.show = false
            this.terrace_id = ''
        },
        submit_distribute() {
            const obj = {
                id: this.id,
                with_terrace_id: this.terrace_id
            }
            
            car_driver_appoint_staff_distribute_request(obj).then((s) => {
                if (s.status === 0) {
                    this.get_car_driver_appoint_details()
                    this.$toast.success('更换成功!')
                } else {
                    this.$message.error(s.msg)
                }
            }).catch((err) => {
                this.$message.error(err)
            })
        },
        get_all_terrace_list() {
            terrace_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllTerrace = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
    },
    filters:{},
    props:{},
    created(){
        this.id = this.$route.query.id
        this.get_car_driver_appoint_details()
        this.get_all_terrace_list()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
</style>